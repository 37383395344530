import { Link } from 'bv-components';

const NavBar = () => (
  <div className="navbar">
    <Link to="/sports/1000/timetable/today">
      <div className="navbar__toggle" />
    </Link>
  </div>
);

export default NavBar;
