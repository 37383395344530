// TODO:
// What this module is doing should be done in the backend
// For some reason the API to fetch next events reused the timetable
// So meetings grouped by today/tomorrow/future are returned
// But we need a proper api that only returns what's needed
// Next events from today or tomorrow (if none for today)

// TODO:
// Lot of things exported with the only purpose of testing
// I would prefer to test the exported method only
// Others will be covered by it
import { getJSON } from 'bv-fetch';
import { isEmpty, flatten } from 'underscore';

export const compareEvents = (a, b) => a.epoch_date - b.epoch_date;

const meetingsToFlatten = (meetings) => (
  !isEmpty(meetings.today) ? meetings.today : meetings.tomorrow
);

export const collectEvents = (meetings) => (
  flatten(Object.values(meetingsToFlatten(meetings)))
    .reduce((acc, curr) => acc.concat(curr.events), [])
    .sort(compareEvents)
);

export default () => getJSON('/bv_greyhounds/sports/1000/meetings')
  .then(collectEvents)
  .catch(() => []);
