import { useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Carousel } from 'bv-components';
import { IEvent } from 'IPropTypes';

import Event from './event';

const Events = ({ events }) => {
  const elCarousel = useRef();
  const history = useHistory();
  const { search } = useLocation();

  const routerTabIndex = parseInt(new URLSearchParams(search).get('tab'), 10);
  const activeTabIndex = routerTabIndex.isNaN ? 0 : routerTabIndex;

  const tabClicked = (tabIndex) => {
    if (activeTabIndex !== tabIndex) history.push({ search: `?tab=${tabIndex}` });
  };

  useEffect(() => {
    if (routerTabIndex > 3) {
      elCarousel.current.scrollTo((routerTabIndex * 45), 0);
    }
  }, []);

  return (
    <div id="meetings" className="greyhounds-meetings">
      <Carousel
        scrollStep={138}
        embedded
        ref={elCarousel}
      >
        {
          events.map((event, i) => (
            <Event
              key={event.id}
              event={event}
              isFirst={i === 0}
              tab={i}
              onTabClick={tabClicked}
              routerTabIndex={routerTabIndex}
            />
          ))
        }
      </Carousel>
    </div>
  );
};

Events.propTypes = {
  events: PropTypes.arrayOf(IEvent).isRequired,
};

export default Events;
