import { getJSON } from 'bv-fetch';
import { first } from 'underscore';

const present = (event) => ({
  ...event,
  id: event.event_id,
});

export default (eventId) => (
  getJSON(`/bv_greyhounds/sports/1000/event/${eventId}`)
    .then(first)
    .then(present)
    .catch(() => null)
);
