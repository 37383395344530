// TODO: Contents is only loaded once the next events are fetched
//
// This is not always needed:
// - If the event id is in the URL it coul be fetched before
// - Navbar timetable button does not have dependencies
// - Using an effect to set the seo meta info, it could be done on event fetch
// - Static SEO Title does not depend on the event, it could be added always
//
// Will address this by using the context to set the event in the container
import { useRef, useEffect } from 'react';
import { pick, first } from 'underscore';
import PropTypes from 'prop-types';
import { useParams, Redirect } from 'react-router';
import { useFetch } from 'bv-hooks';
import { SEOSportTitle } from 'bv-helpers/tabs';
import { seo } from 'bv-services';
import { getSportDescription } from 'bv-services/sports';
import { Spinner, SeoTitle } from 'bv-components';
import MarketCard from 'sportsbook-market-card';

import { IEvent } from 'IPropTypes';
import fetchEvent from '../api/fetch_event';

import Events from './events';
import NavBar from './navbar';
import Timeform from './timeform';
import Streaming from './streaming';

const GreyHoundsContents = ({ events, removed }) => {
  const { eventId = first(events).id } = useParams();
  const mediaStreamingPlayer = useRef(null);

  const [event, isEventLoading] = useFetch(
    () => fetchEvent(eventId),
    [eventId],
  );

  useEffect(() => {
    if (event) {
      seo.setMetaInfo(() => ({
        type: 'pre_event',
        data: pick(event, 'description', 'meeting_name'),
        id: 1000,
      }));
    }
  }, [event]);

  if (eventId && removed.includes(Number(eventId))) {
    return <Redirect to="/sports/1000" />;
  }

  return (
    <>
      <SeoTitle
        defaultHeadingPromise={
          () => getSportDescription(1000).then(SEOSportTitle)
        }
      />
      {(event && event.has_stream) && (
        <Streaming
          eventId={event.id}
          nodeRef={mediaStreamingPlayer}
        />
      )}
      <div className="navbar-container">
        <NavBar />
        <Events
          events={events}
        />
      </div>
      <div ref={mediaStreamingPlayer} />
      {isEventLoading ? <Spinner /> : (
        event && (
          <>
            <Timeform timeFormData={event.event_time_form} />
            <MarketCard
              hasHeader
              open
              market={event}
            />
          </>
        )
      )}
    </>
  );
};

GreyHoundsContents.defaultProps = {
  removed: [],
};

GreyHoundsContents.propTypes = {
  events: PropTypes.arrayOf(IEvent).isRequired,
  removed: PropTypes.arrayOf(PropTypes.number),
};

export default GreyHoundsContents;
