import { ContentLayout } from 'bv-components';
import GreyhoundsContainer from './greyhounds_container';

const GreyhoundsLayout = () => (
  <ContentLayout>
    <div className="greyhounds-wrapper">
      <GreyhoundsContainer />
    </div>
  </ContentLayout>
);

export default GreyhoundsLayout;
