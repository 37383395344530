import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { Link } from 'bv-components';
import classNames from 'classnames';
import { IEvent } from 'IPropTypes';

const Event = ({
  event, isFirst, tab, onTabClick,
}) => {
  const { eventId } = useParams();
  const isActive = eventId ? Number(eventId) === event.id : isFirst;

  return (
    <div className="greyhounds-meetings-item">
      <Link
        to={`/sports/1000/events/${event.id}`}
        className={classNames('bvs-button-tab event-item', { active: isActive })}
        onClick={() => onTabClick(tab)}
      >
        <span className="time">{ event.time }</span>
        <span className="name">{ event.meeting_name }</span>
      </Link>
    </div>
  );
};

Event.defaultProps = {
  isFirst: false,
};

Event.propTypes = {
  isFirst: PropTypes.bool,
  event: IEvent.isRequired,
  tab: PropTypes.number.isRequired,
  onTabClick: PropTypes.func.isRequired,
};

export default Event;
