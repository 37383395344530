import PropTypes from 'prop-types';
import withLazyLoad from 'bv-lazy-load';

const MediaStreaming = withLazyLoad()('mediaStreaming');
const GREYHOUNDS_ID = '433200';

const Streaming = ({ eventId, nodeRef }) => (
  <MediaStreaming
    sportId={GREYHOUNDS_ID}
    eventId={eventId}
    requireLogin={false}
    playerNode={nodeRef.current}
  />
);

Streaming.propTypes = {
  eventId: PropTypes.number.isRequired,
  nodeRef: PropTypes.element.isRequired,
};

export default Streaming;
