import { useState, useEffect } from 'react';
import { pluck } from 'underscore';
import { Spinner } from 'bv-components';
import { useEventBus } from 'bv-hooks';

import fetchNextEvents from './api/fetch_next_events';
import GreyHoundContents from './components/contents';
import NoEvents from './components/no_events';

const greyHoundsBusId = '/schedule/433200';

export const updateEvents = (events, setEvents, setRemoved) => (_, { body: { removed } }) => {
  setEvents(events.filter((e) => !removed.includes(e.id)));
  setRemoved(removed);
};

const initialState = {
  loading: true,
  events: [],
};

const GreyhoundsContainer = () => {
  const [{ loading, events }, setState] = useState(initialState);
  const [removed, setRemoved] = useState([]);

  const setEvents = (newEvents) => {
    setState({
      events: newEvents,
      loading,
    });
  };

  useEventBus(
    greyHoundsBusId,
    updateEvents(events, setEvents, setRemoved),
    [events],
  );

  useEffect(() => {
    if (events.length <= 5) {
      fetchNextEvents().then((newEvents) => {
        setState({
          events: newEvents,
          loading: false,
        });
      });
    }
  }, pluck(events, 'id'));

  if (loading) return <Spinner />;
  if (!events.length) return <NoEvents />;

  return (
    <GreyHoundContents
      events={events}
      removed={removed}
    />
  );
};

export default GreyhoundsContainer;
