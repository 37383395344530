import PropTypes from 'prop-types';

const Rating = ({ n }) => {
  const stars = Array.from(new Array(5))
    // eslint-disable-next-line react/no-array-index-key
    .map((e, i) => (<li key={`star_${i}`} className={i < n ? 'checked' : ''} />));

  return (
    <ul className="rating">
      { stars }
    </ul>
  );
};

Rating.propTypes = {
  n: PropTypes.number.isRequired,
};

export default Rating;
