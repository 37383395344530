import { first, property } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import { OnOff, Card } from 'bv-components';
import { toggle } from 'sportsbook-options-duck';
import { t } from 'bv-i18n';
import { useFeature } from 'bv-hooks';

import { ITimeForm } from 'IPropTypes';
import Rating from './rating';

export const pickRunners = (runners) => (
  first(runners.sort((a, b) => b.star_rating - a.star_rating), 3)
);

const onTimeFormClick = (dispatch) => (e) => {
  dispatch(toggle('timeform', e.target.checked));
};

const hasEyeCatcher = ({ runners }) => (
  runners.some(property('eye_catcher'))
);

const Timeform = ({ timeFormData }) => {
  const dispatch = useDispatch();
  const timeformChecked = useSelector(property(['sportsbookOptions', 'timeformChecked']));
  const [timeformEnabled] = useFeature('timeform');

  if (!(timeformEnabled && timeFormData)) return null;

  return (
    <>
      <Card className="greyhounds-timeform">
        <div className="greyhounds-timeform__info">
          <div>
            <span className="greyhounds-timeform__info-title">{t('race_overview')}</span>
            <span>{timeFormData.race_comment}</span>
          </div>
          <OnOff onClick={onTimeFormClick(dispatch)} checked={timeformChecked} />
        </div>
        {timeformChecked && (
          <ol className="greyhounds-timeform__runners">
            {pickRunners(timeFormData.runners).map((runner) => (
              <li>
                <span className="greyhounds-timeform__runners-name">{runner.runner_name}</span>
                <Rating n={runner.star_rating} key={runner.runner_name} />
              </li>
            ))}
          </ol>
        )}
      </Card>
      {timeformChecked && hasEyeCatcher(timeFormData) && (
        <div className="timeform-label">
          <span className="eye-catcher" />
          <span className="timeform-label__text">{t('time_form.eye_catcher_label')}</span>
        </div>
      )}
    </>
  );
};

Timeform.defaultProps = {
  timeFormData: undefined,
};

Timeform.propTypes = {
  timeFormData: ITimeForm,
};

export default Timeform;
